import { graphql } from "gatsby";
import React, { useEffect, useState } from "react";
import Footer from '../components/build/footer/footer';
import Header from '../components/build/header/header';
import Seo from "../components/seo";

// markup
const TermsOfUsePage = ({ location, data }) => {
  const [htmlContent, setHtmlContent] = useState("");

  useEffect(() => {
    //  const script = document.createElement("script");
    //  script.src = "https://app.termly.io/embed-policy.min.js";
    //  script.async = true;
    //  document.body.appendChild(script);
    //  return () => {
    //    document.body.removeChild(script);
    //  };
    async function fetchHtml() {
      const response = await fetch("/terms.html");
      console.log(response);
      const html = await response.text();
      setHtmlContent(html);
    }
    fetchHtml();
  }, []);
  return (
    <>
      <Seo
        title={"Terms Of Use"}
        description={""}
        pageSeo={data.wpPage}
        location={location}
      />
      <Header scrollToForm=""></Header>
      <main>
        <div className="container">
          <div dangerouslySetInnerHTML={{ __html: htmlContent }}></div>
        </div>
      </main>
      <Footer scrollToForm=""></Footer>
    </>
  );
};

export const query = graphql`
  query {
    wpPage(slug: { eq: "terms-of-use" }) {
      title
      id
      seo {
        metaKeywords
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        focuskw
        opengraphImage {
          localFile {
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        twitterImage {
          localFile {
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        opengraphType
      }
    }
  }
`;

export default TermsOfUsePage;
